import React from "react";
import { Box, Typography, FormControl, TextField } from "@mui/material";

export type AutoDetailsProps = {
  autoCurrentMake: string;
  autoSelectedModel: string;
  autoDisplayDisplacement: number | null;
  autoFuelType?: string;
  autoSelectedVariant: string | null;
  autoVehicleYear: number | string;
  autoGearboxType?: string;
  autoGearboxDriveType?: string;
};

const AutoCompleteFields: React.FC<AutoDetailsProps> = ({
  autoCurrentMake,
  autoSelectedModel,
  autoDisplayDisplacement,
  autoFuelType = "",
  autoSelectedVariant,
  autoVehicleYear,
  autoGearboxType = "",
  autoGearboxDriveType = "",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        paddingTop: "25px",
      }}
    >
      {/* Make Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Make
          </Typography>
          <TextField
            variant="outlined"
            value={autoCurrentMake}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Model Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Model
          </Typography>
          <TextField
            variant="outlined"
            value={autoSelectedModel}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Year Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Year
          </Typography>
          <TextField
            variant="outlined"
            value={autoVehicleYear}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Engine Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Engine
          </Typography>
          <TextField
            variant="outlined"
            value={
              autoDisplayDisplacement && autoFuelType
                ? `${autoDisplayDisplacement} L - ${autoFuelType}`
                : autoDisplayDisplacement
                  ? `${autoDisplayDisplacement} L`
                  : ""
            }
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Gearbox Type Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Gearbox Type
          </Typography>
          <TextField
            variant="outlined"
            value={autoGearboxType}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Gearbox Drive Type Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Gearbox Drive Type
          </Typography>
          <TextField
            variant="outlined"
            value={autoGearboxDriveType}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>

      {/* Variant Field */}
      <FormControl fullWidth>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography
            sx={{
              color: "var(--gray-700, #344054)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Variant
          </Typography>
          <TextField
            variant="outlined"
            value={autoSelectedVariant || ""}
            disabled
            fullWidth
            InputProps={{
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export default AutoCompleteFields;
