import kmIcon from "../../assets/forms/kmIcon.png";
import dayjs from "dayjs";
import dataAnimation from "../../assets/lottie/Servisor_07_Booking Particulars.json";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckBox from "../../assets/icons/checkBox.svg";
import dateIcon from "../../assets/icons/dateIcon.svg";
import distanceIcon from "../../assets/icons/distanceIcon.svg";
import carBlack from "../../assets/icons/carBlack.svg";
import CloseIcon from "../../assets/icons/x-close.png";
import vehicleTag from "../../assets/tags/bookingdetailsnew.svg";
import servisorLogo from "../../assets/servisorWhiteLogo.png";
import React, { useState, useEffect } from "react";
import "./Customize.scss";
import { useHistory } from "react-router-dom";
import { CustomModel } from "../../models/CustomizeModel";
import { useGlobalContext } from "../../services/context/globalContext";
import {
  Container,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  BottomNavigation,
  Paper,
  Backdrop,
  Modal,
  Fade,
  TextField,
  InputAdornment,
} from "@mui/material";
import NextIcon from "../../assets/icons/ChevronRightFilled.svg";
import { HeaderText } from "../../components/common/HeaderText";
import { SubHeaderText } from "../../components/common/SubHeaderText";
import { PageLayout } from "../../components/layout/PageLayout";
import { getDisableDates } from "../../services/utilities/disabledDates";
import { getAllActiveWorkshops } from "../../services/apis/worksshopsData.service";
import IssueHelpButton from "../../components/common/IssueHelpButton";

const Customize: React.FC = () => {
  // @ts-ignore
  const { setServicesInfo } = useGlobalContext();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [customise, setCustomise] = useState<CustomModel>(
    new CustomModel({ distance: 10 }),
  );
  const locationDetails = JSON.parse(localStorage.getItem("location") ?? "");
  const vehicleDetails = JSON.parse(
    localStorage.getItem("vehicle-details") ?? "",
  );
  const maintenanceDetails = JSON.parse(
    localStorage.getItem("maintenance-details") ?? "",
  );

  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [workshopsData, setWorkshopsData] = useState<any[]>([]);
  const [previousDistance, setPreviousDistance] = useState<number>(10);
  const [distanceInputValue, setDistanceInputValue] = useState<string>(
    customise.distance !== null && customise.distance !== undefined
      ? customise.distance.toString()
      : "",
  );
  /**
   * Below Date functions are the min-max time period a user can select a service
   */
  // const today = new Date();
  // const minDate = new Date(
  //   today.getFullYear(),
  //   today.getMonth(),
  //   today.getDate() + 2
  // );

  // const maxDate = new Date(
  //   today.getFullYear(),
  //   today.getMonth(),
  //   today.getDate() + 14
  // );

  let map: google.maps.Map;
  let circle: google.maps.Circle;

  const calculateDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
  ): number => {
    const toRad = (x: number): number => (x * Math.PI) / 180;

    const dRadius = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const dArea =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const dCircle = 2 * Math.atan2(Math.sqrt(dArea), Math.sqrt(1 - dArea));
    const calcDistance = dRadius * dCircle;

    return calcDistance;
  };

  const allWorkShops = async () => {
    setIsLoading(true);
    try {
      const allWorkshopData = await getAllActiveWorkshops();
      setWorkshopsData(allWorkshopData.data);
      // console.log("fecth Data 2: ", workshopsData);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching workshops  data: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    allWorkShops();
  }, []);

  async function initMap(): Promise<void> {
    if (
      !locationDetails ||
      !locationDetails.location ||
      locationDetails.location.length < 2
    ) {
      console.error("Invalid location details");
      return;
    }
    const lat = parseFloat(locationDetails?.location[0]);
    const lng = parseFloat(locationDetails?.location[1]);

    if (isNaN(lat) || isNaN(lng)) {
      console.error("Invalid latitude or longittude.");
      return;
    }

    const radius =
      customise.distance && isNaN(customise.distance)
        ? parseFloat(customise.distance)
        : previousDistance;

    const labourHours = Number(maintenanceDetails?.total_labour_time);
    const carAge = Number(vehicleDetails?.age);

    // console.log("labour: ", labourHours);
    // console.log("car age: ", carAge);

    const workshopWithRadius = workshopsData.filter((workshop: any) => {
      const workshopLat = workshop.latlng[0];
      const workshopLng = workshop.latlng[1];
      // console.log("DATA: ", workshop);

      if (isNaN(workshopLat) || isNaN(workshopLng)) {
        console.error(`Invalid latlng for workshop ${workshop.workshop_name}`);
      }

      const workshopDistance = calculateDistance(
        lat,
        lng,
        workshopLat,
        workshopLng,
      );

      const workshopRadius = workshopDistance <= radius;

      const availableHours = workshop.available_hours >= labourHours;

      const isOldCar = carAge >= 10;
      const isNewCar = carAge < 10;

      const filterCarAge =
        (isOldCar && workshop.old_cars) || (isNewCar && workshop.new_cars);

      // console.log(
      //   `workshop ${workshop.name}: r= ${workshopRadius}, h= ${availableHours}, a= ${filterCarAge}`
      // );

      return workshopRadius && availableHours && filterCarAge;
    });

    const { Map } = (await google.maps.importLibrary(
      "maps",
    )) as google.maps.MapsLibrary;
    const { spherical } = (await google.maps.importLibrary(
      "geometry",
    )) as google.maps.GeometryLibrary;

    map = new Map(document.getElementById("map") as HTMLElement, {
      center: {
        lat: lat,
        lng: lng,
      },
      zoom: 8,
    });

    const infoWindow = new google.maps.InfoWindow();

    // @ts-ignore: Unused variable
    circle = new google.maps.Circle({
      map: map,
      center: {
        lat: parseFloat(locationDetails?.location[0]),
        lng: parseFloat(locationDetails?.location[1]),
      },
      fillColor: "#6695FC",
      fillOpacity: 0.35,
      strokeColor: "#6695FC",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      radius: radius * 1000,
      clickable: false,
      zIndex: -1,
    });

    //compute the bounds of the circle
    const bounds = new google.maps.LatLngBounds();
    const center = circle.getCenter()!;
    const circleRadius = circle.getRadius();

    const northEast = spherical.computeOffset(center, circleRadius, 45);
    const southWest = spherical.computeOffset(center, circleRadius, 225);

    bounds.extend(northEast);
    bounds.extend(southWest);

    //adjust the map viewport
    map.fitBounds(bounds);

    workshopWithRadius.forEach((workshop: any) => {
      const marker = new google.maps.Marker({
        position: {
          lat: workshop.latlng[0],
          lng: workshop.latlng[1],
        },
        map,
        title: workshop.workshop_name,
      });

      marker.addListener("click", () => {
        const contentString = `
          <div style="max-width: 220px; text-align: left;">
            <p><strong>${workshop.workshop_name}</strong></p>
            <p>${workshop.address}</p>
          </div>
        `;
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
      });
    });
  }

  useEffect(() => {
    if (Array.isArray(workshopsData) && workshopsData.length > 0) {
      initMap();
    } else {
      console.log("workshop is not ready yet.");
    }
  }, [customise.distance, workshopsData]);

  /**
   * Assigns a value to a specific field in the customise object.
   *
   * @param {any} event - The event object containing the field value.
   * @param {string} target - The target field identifier.
   */
  const assignField = (event: any, target: string) => {
    switch (target) {
      case "date":
        setCustomise(
          (prev) =>
            new CustomModel({
              ...prev,
              service_date: event ? event : null,
            }),
        );
        break;
      case "distance":
        const newDistance = event.target.value;
        setDistanceInputValue(newDistance);
        if (newDistance !== "" && !isNaN(newDistance)) {
          const parsedDistance = parseFloat(newDistance);
          setPreviousDistance(parsedDistance);
          setCustomise(
            (prev) =>
              new CustomModel({
                ...prev,
                distance: parsedDistance,
              }),
          );
          initMap();
        }
        break;
      // case 'lift':
      //     customise.lift = event.target.value ? event.target.value : null;
      //     break;
    }
    // setCustomise(new CustomModel(customise));
  };

  const marketPriceFetch = JSON.parse(
    localStorage.getItem("maintenance-details") ?? "",
  );

  const saveServicesInfo = () => {
    const params: any = Object.assign({}, customise);

    if (params.service_date && dayjs.isDayjs(params.service_date)) {
      params.service_date = params.service_date.format("YYYY-MM-DDTHH:mm:ssZ");
    }

    setServicesInfo(JSON.stringify(params));

    localStorage.setItem("servicesInfo", JSON.stringify(params));
    history.push(
      // `/results/${customise.distance}/${customise.lift}/${customise.service_date}/${marketPriceFetch.market_price}`

      `/results/${customise.distance}/${customise.service_date}/${marketPriceFetch.market_price}`,
    );
  };

  return (
    <PageLayout animationData={dataAnimation}>
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",
          paddingBottom: "100px",
          position: "relative",
          paddingTop: { xs: "0", lg: "82px" },
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
        }}
      >
        {/* <PageHeader translucent={false} /> */}

        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <img src={vehicleTag} alt="/" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              onClick={() => history.push("/details")}
              sx={{
                padding: "8px",
                marginBottom: "19px",
                display: "flex",
                borderRadius: "10%",
                justifyContent: "center",
                backgroundColor: "#d1ddf8",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={NextIcon}
                  alt="/"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "rotate(180deg)",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#002064",
                  fontWeight: "500",
                  textTransform: "none",
                }}
              >
                Back
              </Typography>
            </Button>
          </Box>
        </Box>

        <HeaderText
          headerText="FIND ME A BETTER DEAL"
          headerImage={servisorLogo}
        />
        <SubHeaderText headerText="One last thing..." isTextCenter={false} />
        <Container
          disableGutters
          sx={{
            width: { xs: "90%", lg: "70%" },
            marginTop: { xs: "-10px", lg: "0px" },
            borderRadius: { xs: "4px", lg: "4px" },
            backgroundColor: "#FFF",
            paddingX: { xs: "10px", lg: "0" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              alignSelf: "left",
              width: { xs: "100%", lg: "70%" },
              paddingTop: { xs: "20px", lg: "0" },
              paddingLeft: "0",
            }}
          >
            <img src={vehicleTag} alt="/" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Box>
              <Box>
                <Box sx={{ pt: "20px" }}>
                  <img src={dateIcon} alt="/" />
                </Box>
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  When would you like your service to take place?
                </Typography>
              </Box>
              <form>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date"
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                      }}
                      shouldDisableDate={(date: any) => {
                        const today = new Date();
                        const nextServiceDate = new Date(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate() + 2,
                        );
                        const disabledDates = getDisableDates();

                        const disable = disabledDates.some(
                          (disabledDate) =>
                            date.date() === disabledDate.date() &&
                            date.month() === disabledDate.month(),
                        );
                        const isSunday = date.day() === 0;

                        return date < nextServiceDate || disable || isSunday;
                      }}
                      onChange={(date) => {
                        assignField(date, "date");
                      }}
                      sx={{ width: "100%" }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </form>
            </Box>

            <Box>
              <Box>
                <img src={distanceIcon} alt="/" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  How far are you willing to travel to the workshop?
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(16, 24, 40, 0.70)",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <strong>NOTE:</strong> This radius includes your drop off and
                  pick up location.
                </Typography>
              </Box>
            </Box>

            <div id="map" className="map"></div>
            <Box>
              <Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    ></Typography>

                    <TextField
                      value={distanceInputValue}
                      InputProps={{
                        sx: {
                          borderRadius: "8px",
                          width: "100%",
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                          '& input[type="number"]': {
                            "-moz-appearance": "textfield",
                          },
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src={kmIcon} alt="/" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="10"
                      type="number"
                      onChange={(ev) => {
                        assignField(ev, "distance");
                      }}
                      onBlur={() => {
                        if (
                          distanceInputValue === "" ||
                          isNaN(Number(distanceInputValue))
                        ) {
                          setDistanceInputValue(previousDistance.toString());
                        }
                      }}
                    />
                  </Box>
                </Box>

                {/* </IonItem> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {locationDetails.description}
                    </Typography>
                  </Box>
                  <Box>
                    <Button onClick={handleOpen}>
                      <Typography
                        sx={{
                          color: "#004FFA",
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        Edit
                      </Typography>
                    </Button>

                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box>
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: {
                                xs: "90%",
                                lg: "30%",
                              },
                              backgroundColor: "#FFF",
                              borderRadius: "8px",
                              boxShadow: "24",
                              padding: "24px 16px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#002064",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Edit Location{" "}
                                </Typography>
                              </Box>
                              <Box>
                                <Button onClick={handleClose}>
                                  <img src={CloseIcon} alt="/" />
                                </Button>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  mt: 2,
                                  fontSize: "16px",
                                  color: "#525456",
                                  fontWeight: "400",
                                }}
                              >
                                Labour rates are calculated based on workshop
                                location.{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: "16px",
                                  color: "#525456",
                                  fontWeight: "400",
                                }}
                              >
                                Changing this may affect the cost of the
                                service{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <Button
                                onClick={() => history.push("onboarding/4")}
                                variant="contained"
                                fullWidth
                                sx={{
                                  borderRadius: "var(--1, 8px)",
                                  padding: "10px 18px",
                                  backgroundColor: "#004FFA",
                                  ":hover": {
                                    backgroundColor: "#002064",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#FFF",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Continue
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={handleClose}
                                fullWidth
                                sx={{
                                  borderRadius: "var(--1, 8px)",
                                  padding: "10px 18px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    color: "var(--gray-700, #344054)",
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Fade>
                    </Modal>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              {/* <Box>
                  <Box>
                    <img src={carBlack} alt="/" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Do you require a lift from the workshop after dropping off
                      your vehicle?
                    </Typography>
                  </Box>
                </Box> */}

              <Box>
                {/* <RadioGroup
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={customise.lift}
                    onChange={(ev) => {
                      assignField(ev, "lift");
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                        py: "10px",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="true"
                            name="true"
                            icon={
                              <Box
                                sx={{
                                  border: "1px solid var(--gray-300, #D0D5DD)",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "10px",
                                }}
                              ></Box>
                            }
                            checkedIcon={
                              <img
                                src={CheckBox}
                                alt="tick"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            }
                          />
                        }
                        label="                    Yes please!"
                        sx={{
                          margin: "0px",

                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",

                          borderRadius: "8px",
                          padding: "16px",
                          border:
                            customise.lift === "true"
                              ? "1px solid #004FFA"
                              : "1px solid var(--gray-200, #EAECF0)",
                          backgroundColor:
                            customise.lift === "true" ? "#E9F0FF" : "",
                          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            value="false"
                            name="false"
                            icon={
                              <Box
                                sx={{
                                  border: "1px solid var(--gray-300, #D0D5DD)",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "10px",
                                }}
                              ></Box>
                            }
                            checkedIcon={
                              <img
                                src={CheckBox}
                                alt="tick"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            }
                          />
                        }
                        label="No thanks"
                        sx={{
                          margin: "0px",
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          flexGrow: "2",
                          borderRadius: "8px",
                          padding: "16px",

                          border:
                            customise.lift === "false"
                              ? "1px solid #004FFA"
                              : "1px solid var(--gray-200, #EAECF0)",
                          backgroundColor:
                            customise.lift === "false" ? "#E9F0FF" : "",
                          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        }}
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            value="notsure"
                            name="notsure"
                            icon={
                              <Box
                                sx={{
                                  border: "1px solid var(--gray-300, #D0D5DD)",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "10px",
                                }}
                              ></Box>
                            }
                            checkedIcon={
                              <img
                                src={CheckBox}
                                alt="tick"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            }
                          />
                        }
                        label="I'm not sure"
                        sx={{
                          margin: "0px",
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          flexGrow: "2",
                          borderRadius: "8px",
                          padding: "16px",
                          border:
                            customise.lift === "notsure"
                              ? "1px solid #004FFA"
                              : "1px solid var(--gray-200, #EAECF0)",

                          backgroundColor:
                            customise.lift === "notsure" ? "#E9F0FF" : "",
                          color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        }}
                      />
                    </Box>
                  </RadioGroup> */}
              </Box>
            </Box>
          </Box>
          <IssueHelpButton />

          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              zIndex: "99",
              paddingY: "20px",
              width: { xs: "100%", lg: "50%" },
              borderRadius: "0",
            }}
            elevation={0}
          >
            <BottomNavigation>
              <Button
                variant="contained"
                sx={{
                  height: "60px",
                  paddingY: "10px",
                  width: { xs: "90%", lg: "544px" },
                  backgroundColor: "#004FFA",
                  ":hover": {
                    backgroundColor: "#002064",
                  },
                }}
                disabled={
                  !customise.service_date ||
                  customise.distance === null ||
                  customise.distance === undefined ||
                  customise.distance === ""
                  //  ||
                  // !customise.lift
                }
                onClick={() => {
                  saveServicesInfo();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Show me some deals!
                  </Typography>
                </Box>
              </Button>
            </BottomNavigation>
          </Paper>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Customize;
