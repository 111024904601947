import {
  IonContent,
  IonFooter,
  IonTabs,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonTabBar,
  IonTab,
  IonTabButton,
  IonLabel,
  IonBadge,
  setupConfig,
  useIonToast,
} from "@ionic/react";

import dataAnimation from "../../assets/lottie/Servisor_09_Confirmation_1.json";
import servisorIcon from "../../assets/servisorWhiteLogo.png";

import { v4 as uuid } from "uuid";
import "./Payment.scss";
import React, { useEffect, useState } from "react";
import PayIcon from "../../assets/payment.svg";
import { getuser } from "../../services/apis/user.service";
import {
  sendConfirmationEmail,
  sendWorkshopEmail,
} from "../../services/apis/booking.service";
import { useGlobalContext } from "../../services/context/globalContext";
import { useHistory } from "react-router";
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  BottomNavigation,
  CircularProgress,
} from "@mui/material";
import { PageLayout } from "../../components/layout/PageLayout";
import { userInfo } from "os";
import { usePreviousMonthDisabled } from "@mui/x-date-pickers/internals";
import { createBooking } from "../../services/apis/api.service";
import { HeaderText } from "../../components/common/HeaderText";

const Payment: React.FC = () => {
  const history = useHistory();

  // const history = useHistory();
  // const [present, dismiss] = useIonToast();
  // const [user, setUser]: any = useState(null);
  // const [userDetails, setUserDetails]: any = useState();
  // const [workshopDetails, setworkshopDetails]: any = useState();

  // useEffect(() => {
  //   const serviceInfo: any = JSON.parse(
  //     localStorage.getItem("servicesInfo") || ""
  //   );
  //   const formattedDate = serviceInfo.service_date.split("T");

  //   const workshopName: string = localStorage.getItem("workshop-name") || "";
  //   console.log("workshop name", workshopName);

  //   const workshopEmail: string = localStorage.getItem("workshop-email") || "";
  //   console.log("workshop email", workshopEmail);

  //   const userInfo: any = JSON.parse(
  //     localStorage.getItem("user-details") || ""
  //   );
  //   console.log("userInfo", userInfo);
  //   const vehicleInfo: any = JSON.parse(
  //     localStorage.getItem("vehicle-details") || ""
  //   );
  //   const formattedVehicleInfo = `${vehicleInfo.VEHICLE_MAKE} ${vehicleInfo.VEHICLE_MODEL}, ${vehicleInfo.VEHICLE_ENGINE}, ${vehicleInfo.year},`;
  //   console.log("vehicleInfo", formattedVehicleInfo);

  //   const bookingID = localStorage.getItem("booking-id") || "";
  //   console.log("booking id", bookingID);

  //   console.log(
  //     userInfo.phonenumber,
  //     userInfo.name,
  //     userInfo.email,
  //     bookingID,
  //     vehicleInfo.make,
  //     workshopName,
  //     serviceInfo.service_date,
  //     userInfo.email
  //   );

  //   setUserDetails({
  //     receiver_email: userInfo.email,
  //     name: userInfo.name,
  //     booking_date: formattedDate[0],
  //     workshop_name: workshopName,
  //     vehicle: formattedVehicleInfo,
  //     booking_id: bookingID,
  //   });

  //   setworkshopDetails({
  //     receiver_email: workshopEmail,
  //     booking_date: formattedDate[0],
  //     workshop_name: workshopName,
  //     vehicle: formattedVehicleInfo,
  //     customer_name: userInfo.name,
  //     customer_email: userInfo.email,
  //     customer_number: userInfo.phonenumber,
  //     booking_id: bookingID,
  //   });
  // }, []);

  // const handleSubmit = () => {
  //   sendConfirmationEmail(userDetails).then((data) => {
  //     sendWorkshopEmail(workshopDetails).then((data) => {
  //       console.log("workshop response", data);
  //       present({
  //         buttons: [
  //           {
  //             text: "hide",
  //             handler: () => dismiss(),
  //           },
  //         ],
  //         message: data.msg.toUpperCase(),
  //       });
  //     });

  //     console.log("response", data);
  //     present({
  //       buttons: [
  //         {
  //           text: "hide",
  //           handler: () => dismiss(),
  //         },
  //       ],
  //       message: data.msg.toUpperCase(),
  //     });
  //   });
  const currUrl = window.location.href;

  // const urlParts = currUrl.split('/');
  // const urlId = urlParts[urlParts.length - 1];

  const [workshopData, setWorkshopData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingCompleted, setBookingCompleted] = useState(false);

  const selectedWorkshopDetails = JSON.parse(
    localStorage.getItem("selected-workshop-details") ?? "{}",
  );

  console.log("getting the right data: ", workshopData);

  const maintenanceDetails = JSON.parse(
    localStorage.getItem("maintenance-details") ?? "{}",
  );
  // const discountPercentage =
  //     selectedWorkshopDetails?.data[0].percentage_saved;

  // const discountedWorkshopPrice =
  //     selectedWorkshopDetails?.data[0].discounted_workshop_price;

  const serviceDetails = JSON.parse(
    localStorage.getItem("servicesInfo") ?? "{}",
  );

  const details = JSON.parse(
    localStorage.getItem("details-evaluation") ?? "{}",
  );

  const vehicleInfomation = JSON.parse(
    localStorage.getItem("vehicle-details") ?? "{}",
  );

  const serviceInfomation = JSON.parse(
    localStorage.getItem("ServicePlan") ?? "{}",
  );

  const conditionalServiceFee = Number(
    localStorage.getItem("service_fee") ?? "{}",
  );
  console.log("The conditional service: ", conditionalServiceFee);
  const conditionalWorkshopDeposit = Number(
    localStorage.getItem("discounted_workshop_price") ?? "{}",
  );
  console.log("The conndtional workshop deposit: ", conditionalWorkshopDeposit);

  const locationInfo = JSON.parse(localStorage.getItem("location") ?? "{}");

  const areaCodeDetails = localStorage.getItem("areaCode") ?? "";

  const uuidData = localStorage.getItem("uuid-data") ?? "";

  const urlID = localStorage.getItem("urlId") ?? "";

  const selectedWorkshopData = selectedWorkshopDetails?.data.find(
    (workshop: any) =>
      workshop._id === urlID.replace(/\\/g, "").replace(/"/g, ""),
  );

  const marketPrice = Number(maintenanceDetails?.market_price);
  const workShopServiceFee = Number(selectedWorkshopData?.service_fee);
  const workShopDepositPrice = Number(
    selectedWorkshopData?.discounted_workshop_price,
  );

  let serviceFee = 0;
  let workshopDeposit = 0;

  if (marketPrice === 2500) {
    serviceFee = conditionalServiceFee;
    workshopDeposit = conditionalWorkshopDeposit;
  } else {
    serviceFee = Number(selectedWorkshopData?.service_fee);
    workshopDeposit = Number(selectedWorkshopData?.discounted_workshop_price);
  }

  const fetchDataAndSecureBooking = async () => {
    if (bookingCompleted) return;

    const selectedWorkshop = selectedWorkshopDetails?.data.find(
      (workshop: any) =>
        workshop._id === urlID.replace(/\\/g, "").replace(/"/g, ""),
    );

    if (!selectedWorkshop) {
      console.error("No matching workshop found for the given URL ID.");
      history.push("/booking-error");
    }

    if (!urlID || !localStorage.length || !selectedWorkshop) {
      history.push("/onboarding/1");
      return;
    }

    setIsLoading(true);

    try {
      const data = {
        name: details?.name,
        surname: details?.surname,
        phone_number: details?.phonenumber,
        email: details?.email,
        registration_number: maintenanceDetails.vehicle_registration,
        milleage: vehicleInfomation.vehicleMilleage,
        service_plan: serviceInfomation,
        location: locationInfo.description,
        place_id: locationInfo.place_id,
        description: locationInfo.description,
        service_date: serviceDetails.service_date,
        distance: serviceDetails.distance,
        address: selectedWorkshop.address,
        original_parts: maintenanceDetails.items.map(
          (item: { price_desc: string }) => item.price_desc,
        ),
        market_price: maintenanceDetails.market_price,
        parts_needed: maintenanceDetails.items.map(
          (item: {
            price_desc: string;
            quantity: number;
            item_code: string;
          }) => ({
            part: item.price_desc,
            amount: item.quantity,
            itemcode: item.item_code,
          }),
        ),

        labour: maintenanceDetails.total_labour_time,
        lat: locationInfo?.location[0],
        long: locationInfo?.location[1],
        latlng: `${
          locationInfo?.location[0] + "," + locationInfo?.location[1]
        }`,
        workshop_name: selectedWorkshop.workshop_name,
        service_fee: serviceFee,
        discounted_workshop_price: workshopDeposit,
        workshop_email: selectedWorkshop.email,
        area_code: areaCodeDetails,
        province: selectedWorkshop.province.name,
        status: "Pending",
        vehicle_details: {
          make: maintenanceDetails.vehicle_make,
          model: maintenanceDetails.vehicle_model,
          engine_capacity: `${vehicleInfomation.displayDisplacement}L ${vehicleInfomation.fuel_type} `,
          gearbox_type: vehicleInfomation.transmission,
          year: vehicleInfomation.year,
          gearbox_drive_type: vehicleInfomation.drive,
          vin:
            vehicleInfomation.vin !== undefined ? vehicleInfomation.vin : "N/A",
        },
        workshop_id: selectedWorkshop._id,

        uuid: uuidData.replace(/\\/g, "").replace(/"/g, ""),
      };

      await createBooking(data);
      setBookingCompleted(true);
    } catch (error) {
      setIsLoading(false);
      history.push("/booking-error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!bookingCompleted) {
      fetchDataAndSecureBooking();
    }
  }, [[urlID, selectedWorkshopDetails, history, bookingCompleted]]);

  //   useEffect(() => {
  //     const fetchData = () => {
  //       console.log("Running fetchData...");

  //       const selectedWorkshop = selectedWorkshopDetails?.data.find(
  //         (workshop: any) =>
  //           workshop._id === urlID.replace(/\\/g, "").replace(/"/g, "")
  //       );

  //       if (!selectedWorkshop) {
  //         console.error("No matching workshop found for the given URL ID.");
  //         history.push("/booking-error");
  //       }

  //       setWorkshopData(selectedWorkshop);
  //       setIsLoading(false);
  //     };

  //     fetchData();
  //   }, [urlID, selectedWorkshopDetails, history]);

  //   const secureBooking = async () => {
  //     if (!urlID || !localStorage.length || !workshopData) {
  //       history.push("/home");
  //       return;
  //     }

  //     console.log("SELECTED WORKSHOP DATA: ", workshopData);
  //     setIsLoading(true);

  //     try {
  //       const data = {
  //         name: details?.name,
  //         surname: details?.surname,
  //         phone_number: details?.phonenumber,
  //         email: details?.email,
  //         registration_number: maintenanceDetails.vehicle_registration,
  //         milleage: vehicleInfomation.vehicleMilleage,
  //         service_plan: serviceInfomation,
  //         location: locationInfo.description,
  //         place_id: locationInfo.place_id,
  //         description: locationInfo.description,
  //         service_date: serviceDetails.service_date,
  //         distance: serviceDetails.distance,
  //         address: workshopData.address,
  //         original_parts: maintenanceDetails.items.map(
  //           (item: { price_desc: string }) => item.price_desc
  //         ),
  //         market_price: maintenanceDetails.market_price,
  //         parts_needed: maintenanceDetails.items.map(
  //           (item: { price_desc: string; quantity: number }) => ({
  //             part: item.price_desc,
  //             amount: item.quantity,
  //           })
  //         ),

  //         labour: maintenanceDetails.total_labour_time,
  //         lat: locationInfo?.location[0],
  //         long: locationInfo?.location[1],
  //         latlng: `${
  //           locationInfo?.location[0] + "," + locationInfo?.location[1]
  //         }`,
  //         workshop_name: workshopData.workshop_name,
  //         service_fee: workshopData.service_fee,
  //         discounted_workshop_price: workshopData.discounted_workshop_price,
  //         workshop_email: workshopData.email,
  //         area_code: areaCodeDetails,
  //         province: workshopData.province.name,
  //         status: "Pending",
  //         vehicle_details: {
  //           make: maintenanceDetails.vehicle_make,
  //           model: maintenanceDetails.vehicle_model,
  //           engine_capacity: `${vehicleInfomation.displayDisplacement}L ${vehicleInfomation.fuel_type} `,
  //           gearbox_type: vehicleInfomation.transmission,
  //           year: vehicleInfomation.year,
  //           gearbox_drive_type: vehicleInfomation.drive,
  //         },
  //         workshop_id: workshopData._id,

  //         uuid: uuidData.replace(/\\/g, "").replace(/"/g, ""),
  //       };

  //       console.log("My booking data: ", data);

  //       await createBooking(data);
  //     } catch (error) {
  //       setIsLoading(false);
  //       history.push("/booking-error");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  const userEmail = JSON.parse(
    localStorage.getItem("details-evaluation") ?? "{}",
  );

  //   useEffect(() => {
  //     if (workshopData) {
  //         secureBooking();
  //     }
  //   }, [workshopData]);

  const pushHome = () => {
    localStorage.clear();
    if (window.top) {
      window.top.location.href = "/onboarding/1";
    }
  };

  return (
    <PageLayout animationData={dataAnimation}>
      {isLoading ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          <HeaderText
            headerText="PERSONAL DETAILS"
            headerImage={servisorIcon}
          />{" "}
          <Container
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <img src={PayIcon} className="pay-icon" alt="/" /> */}

              <Typography
                sx={{
                  color: "#002064",
                  fontSize: { xs: "18px", lg: "24px" },
                  fontWeight: "500",
                  width: { xs: "100%", lg: "100%" },
                  textAlign: { xs: "start", lg: "center" },
                }}
              >
                Your Booking has been confirmed. Thank you for using Servisor.
              </Typography>

              <Typography
                sx={{
                  color: "#002064",
                  fontSize: { xs: "18px", lg: "24px" },
                  fontWeight: "500",
                  width: { xs: "100%", lg: "100%" },
                  textAlign: { xs: "start", lg: "center" },
                }}
              >
                An email with all the relevant details will be sent to:{" "}
              </Typography>

              <Typography
                sx={{
                  color: "#002064",
                  fontSize: { xs: "18px", lg: "24px" },
                  fontWeight: "500",
                  width: { xs: "100%", lg: "100%" },
                  textAlign: { xs: "start", lg: "center" },
                }}
              >
                {userEmail.email}
              </Typography>

              {/* {userDetails ? ( */}

              {/* ) : null} */}
              {/* <Button
variant="contained"
// color="light"
// expand="block"
// onClick={() => {
//   handleSubmit();
// }}
>
<Typography sx={{color: '#FFF', textTransform: 'capitalize'}}>
Send Email

</Typography>
</Button> */}
            </Box>

            <Paper
              sx={{
                position: "fixed",
                bottom: 0,
                right: 0,
                zIndex: "99",
                paddingY: "20px",
                width: { xs: "100%", lg: "50%" },
                borderRadius: "0",
              }}
              elevation={0}
            >
              <BottomNavigation showLabels={false}>
                <Button
                  variant="contained"
                  sx={{
                    height: "60px",
                    paddingY: "10px",
                    width: { xs: "90%", lg: "544px" },
                    backgroundColor: "#004FFA",
                  }}
                  onClick={() => {
                    pushHome();
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFF",
                        textTransform: "none",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Complete{" "}
                    </Typography>
                  </Box>
                </Button>
              </BottomNavigation>
            </Paper>
          </Container>
        </>
      )}
    </PageLayout>
  );
};

export default Payment;
