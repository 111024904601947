import dataAnimation from "../../../assets/lottie/Servisor_02_Vehicle Details V2.json";
import NextIcon from "../../../assets/icons/ChevronRightFilled.svg";
import servisorIcon from "../../../assets/servisorWhiteLogo.png";
import kmIcon from "../../../assets/forms/kmIcon.png";
import vehicleTag from "../../../assets/forms/vehicleTag.svg";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import plusIcon from "../../../assets/forms/plus.svg";
import {
  customModel,
  getManualMakeApi,
  getManualModelApi,
  getModelYearsApi,
  customYear,
  customGear,
  calculateVehicleAge,
  getModelEngineDataApi,
  getModelGearboxTypeDataApi,
  getModelGearboxDriveTypeApi,
  getModelDetailsApi,
} from "../../../services/utilities/vehicleUtilities";
import {
  emsGetVehicleMakes,
  emsGetVehicleModels,
  emsGetModelYears,
  emsGetModelEngineData,
  emsGetModelGearboxType,
  emsGetModelGearboxDrive,
  emsGetModelDetails,
  umcCodes,
  carDetails,
} from "../../../services/apis/ems.service";
import {
  TextField,
  Box,
  Container,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Typography,
  FormControl,
  BottomNavigation,
  Paper,
  InputAdornment,
  Tooltip,
  tooltipClasses,
  styled,
  TooltipProps,
} from "@mui/material";
import { HeaderText } from "../../../components/common/HeaderText";
import { SubHeaderText } from "../../../components/common/SubHeaderText";
import ProgressBar from "../../../components/common/ProgressBar";
import { PageLayout } from "../../../components/layout/PageLayout";
import IssueHelpButton from "../../../components/common/IssueHelpButton";
import Loader from "../../../components/Loading/Loading";
import { customerDataExtract } from "../../../services/apis/customer.service";
import { getTheVinRegistrationNumber } from "../../../services/apis/api.service";
import AutoCompleteFields from "../autocomplete/autoComplete";
import VinPopupComponent from "../../error/vinPopUp";

type VariantList = {
  umc: string;
  variant: string;
  kw: number;
  nm: number;
  cc2: string;
  model_class: string;
  [key: string]: any;
};

const ManualSearch: React.FC<any> = () => {
  // !EMS auth token
  const history = useHistory();
  const emsAuthToken = window.localStorage.getItem("ems-auth-token");

  const customerDetailsString =
    window.localStorage.getItem("details-evaluation") || "{}";
  const customerDetails = JSON.parse(customerDetailsString);

  const [clicked, setClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMake, setLoadingMake] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);
  const [loadingModelDetails, setLoadingModelDetails] = useState(false);
  const [loadingEngine, setLoadingEngine] = useState(false);
  const [loadingGearboxType, setLoadingGearboxType] = useState(false);
  const [loadingGearboxDrive, setLoadingGearboxDrive] = useState(false);
  const [vehiclereg, setVehiclereg]: any = useState("");
  const [vehicleMilleage, setVehicleMilleage]: any = useState(0);
  const [vehicleregFocus, setVehicleregFocus] = useState(false);
  const [vehicleMilleageFocus, setVehicleMilleageFocus] = useState(false);
  const [vehicleregEdited, setVehicleregEdited] = useState(false);
  const [vehicleMilleageEdited, setVehicleMilleageEdited] = useState(false);
  const [Makes, setMakes] = useState([]);
  const [CurrentMake, setCurrentMake] = useState("");
  const [Variants, setVariants] = useState([]);
  const [selectedRegistrationNumber, setSelectedRegistrationNumber] =
    useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelYear, setSelectedModelYear] = useState(0);
  const [selectedModelAge, setSelectedModelAge] = useState("");
  const [selectedEngine, setSelectedEngine]: any = useState({});
  const [engineList, setEngineList]: any = useState([]);
  const [selectedGearboxType, setSelectedGearboxType]: any = useState("");
  const [gearboxTypeList, setGearboxTypeList]: any = useState([]);
  const [selectedGearboxDrive, setSelectedGearboxDrive] = useState("");
  const [gearboxDriveList, setGearboxDriveList]: any = useState([]);
  const [modelDetailsList, setModelDetailsList]: any = useState([]);
  const [vehicleYears, setVehicleYears] = useState<number[]>([]);
  const [autoPopulate, setAutoPopulate] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<VariantList | null>(
    null,
  );
  const [isRestoring, setIsRestoring] = useState(true);
  const [showManualFields, setShowManualFields] = useState(false);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("modelMakeData") || "{}");

    if (savedData) {
      if (savedData.vehiclereg) setVehiclereg(savedData.vehiclereg);
      if (savedData.vehicleMilleage)
        setVehicleMilleage(savedData.vehicleMilleage);
      if (savedData.selectedRegistrationNumber)
        setSelectedRegistrationNumber(savedData.selectedRegistrationNumber);
      if (savedData.selectedModel) setSelectedModel(savedData.selectedModel);
      if (savedData.selectedModelYear)
        setSelectedModelYear(savedData.selectedModelYear);
      if (savedData.selectedModelAge)
        setSelectedModelAge(savedData.selectedModelAge);
      if (savedData.selectedEngine) setSelectedEngine(savedData.selectedEngine);
      if (savedData.selectedGearboxType)
        setSelectedGearboxType(savedData.selectedGearboxType);
      if (savedData.selectedGearboxDrive)
        setSelectedGearboxDrive(savedData.selectedGearboxDrive);
      if (savedData.selectedVariant)
        setSelectedVariant(savedData.selectedVariant);
      if (savedData.CurrentMake) setCurrentMake(savedData.CurrentMake);
    }
    setIsRestoring(false);
  }, []);

  useEffect(() => {
    if (!isRestoring) {
      const dataToSave = {
        vehiclereg,
        vehicleMilleage,
        selectedRegistrationNumber,
        selectedModel,
        selectedModelYear,
        selectedModelAge,
        selectedEngine,
        selectedGearboxType,
        selectedGearboxDrive,
        selectedVariant,
        CurrentMake,
      };
      localStorage.setItem("modelMakeData", JSON.stringify(dataToSave));
    }
  }, [
    vehiclereg,
    vehicleMilleage,
    selectedRegistrationNumber,
    selectedModel,
    selectedModelYear,
    selectedModelAge,
    selectedEngine,
    selectedGearboxType,
    selectedGearboxDrive,
    selectedVariant,
    CurrentMake,
    isRestoring,
  ]);

  useEffect(() => {
    if (!isRestoring) {
      const dataToSave = {
        vehiclereg,
        vehicleMilleage,
        selectedRegistrationNumber,
        selectedModel,
        selectedModelYear,
        selectedModelAge,
        selectedEngine,
        selectedGearboxType,
        selectedGearboxDrive,
        selectedVariant,
        CurrentMake,
      };
      localStorage.setItem("modelMakeData", JSON.stringify(dataToSave));

      // Prepare data to send to the API
      const dataToSend = {
        ...customerDetails,
        ...dataToSave,
      };

      Object.keys(dataToSend).forEach((key) => {
        if (dataToSend[key] == null) {
          delete dataToSend[key];
        }
      });

      const timeoutId = setTimeout(() => {
        customerDataExtract(dataToSend)
          .then((res) => {
            // console.log(res);
          })
          .catch((error) => {
            console.error("Error updating customer data: ", error);
          });
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    vehiclereg,
    vehicleMilleage,
    selectedRegistrationNumber,
    selectedModel,
    selectedModelYear,
    selectedModelAge,
    selectedEngine,
    selectedGearboxType,
    selectedGearboxDrive,
    selectedVariant,
    CurrentMake,
    isRestoring,
  ]);

  useEffect(() => {
    if (!emsAuthToken) {
      localStorage.removeItem("modelMakeData");
      history.push("/onboarding/1");
    } else {
      const bodyData = JSON.stringify({ access_token: emsAuthToken });
      getManualMakeApi(
        setMakes,
        setLoadingMake,
        setClicked,
        emsGetVehicleMakes,
        bodyData,
        history,
      );
    }
  }, [emsAuthToken]);

  useEffect(() => {
    if (!isRestoring && CurrentMake) {
      const carMake = CurrentMake.toUpperCase();
      const bodyData = JSON.stringify({
        access_token: emsAuthToken,
        model: carMake,
      });

      getManualModelApi(
        setLoadingModel,
        setVariants,
        setClicked,
        emsGetVehicleModels,
        bodyData,
      );
    }
  }, [emsAuthToken, CurrentMake, isRestoring]);

  useEffect(() => {
    if (!isRestoring && CurrentMake && selectedModel) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
      });

      getModelYearsApi(
        setLoadingYears,
        setClicked,
        setVehicleYears,
        emsGetModelYears,
        bodyData,
      );
    }
  }, [selectedModel, CurrentMake, isRestoring]);

  useEffect(() => {
    if (!isRestoring && CurrentMake && selectedModel && selectedModelYear) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
      });

      getModelEngineDataApi(
        setLoadingEngine,
        setClicked,
        setEngineList,
        emsGetModelEngineData,
        bodyData,
        vehicleYears,
        selectedModelYear,
      );

      calculateVehicleAge(selectedModelYear, setSelectedModelAge);
    }
  }, [selectedModelYear, CurrentMake, selectedModel, isRestoring]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedEngine.displacement &&
      selectedEngine.fuel_type
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
      });

      getModelGearboxTypeDataApi(
        setLoadingGearboxType,
        setClicked,
        setGearboxTypeList,
        emsGetModelGearboxType,
        bodyData,
        vehicleYears,
        selectedModelYear,
      );
    }
  }, [
    selectedEngine,
    CurrentMake,
    selectedModel,
    selectedModelYear,
    isRestoring,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedGearboxType
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
        model_gearbox_types: selectedGearboxType,
      });

      getModelGearboxDriveTypeApi(
        setLoadingGearboxDrive,
        setClicked,
        setGearboxDriveList,
        emsGetModelGearboxDrive,
        bodyData,
        vehicleYears,
        selectedModelYear,
      );
    }
  }, [
    selectedGearboxType,
    CurrentMake,
    selectedModel,
    selectedModelYear,
    selectedEngine,
    isRestoring,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedGearboxType &&
      selectedGearboxDrive
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
        model_gearbox_types: selectedGearboxType,
        model_drive_types: selectedGearboxDrive,
      });

      getModelDetailsApi(
        setLoadingModelDetails,
        setClicked,
        setModelDetailsList,
        emsGetModelDetails,
        bodyData,
        vehicleYears,
        selectedModelYear,
      );
    }
  }, [
    selectedGearboxDrive,
    selectedGearboxType,
    selectedEngine,
    selectedModelYear,
    selectedModel,
    CurrentMake,
    isRestoring,
  ]);

  //The part will the checker for the auto populate
  useEffect(() => {
    if (
      !isRestoring &&
      selectedModel &&
      selectedModelYear > 0 &&
      engineList.length === 1 &&
      !selectedEngine?.displacement
    ) {
      const singleEngine = engineList[0];
      setSelectedEngine(singleEngine);
      setSelectedGearboxType("");
      setSelectedGearboxDrive("");
      setSelectedVariant(null);
    }
  }, [
    engineList,
    isRestoring,
    selectedEngine,
    selectedModel,
    selectedModelYear,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      selectedModel &&
      selectedModelYear > 0 &&
      gearboxTypeList.length === 1 &&
      selectedEngine?.displacement &&
      (!selectedGearboxType || selectedGearboxType !== gearboxTypeList[0])
    ) {
      setSelectedGearboxType(gearboxTypeList[0]);
      setSelectedGearboxDrive("");
      setSelectedVariant(null);
    }
  }, [
    gearboxTypeList,
    isRestoring,
    selectedGearboxType,
    selectedEngine,
    selectedModel,
    selectedModelYear,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      selectedModel &&
      selectedModelYear > 0 &&
      gearboxDriveList.length === 1 &&
      selectedGearboxType &&
      !selectedGearboxDrive
    ) {
      setSelectedGearboxDrive(gearboxDriveList[0]);
      setSelectedVariant(null);
    }
  }, [
    gearboxDriveList,
    isRestoring,
    selectedGearboxDrive,
    selectedGearboxType,
    selectedModel,
    selectedModelYear,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      selectedModel &&
      selectedModelYear > 0 &&
      modelDetailsList.length === 1 &&
      selectedGearboxDrive &&
      !selectedVariant?.umc
    ) {
      const singleVariant = modelDetailsList[0];
      if (!selectedVariant || selectedVariant.umc !== singleVariant.umc) {
        setSelectedVariant(singleVariant);
        localStorage.setItem(
          "vehicle-details",
          JSON.stringify({
            ...singleVariant,
            vehicle_make: CurrentMake,
            displayDisplacement: selectedEngine.display,
            vehicleMilleage: vehicleMilleage,
            registration_number: vehiclereg,
            age: selectedModelAge,
          }),
        );
      }
    }
  }, [
    modelDetailsList,
    isRestoring,
    selectedVariant,
    CurrentMake,
    selectedEngine,
    vehicleMilleage,
    vehiclereg,
    selectedModelAge,
    selectedGearboxDrive,
    selectedModel,
    selectedModelYear,
  ]);

  const [registrationError, setRegistrationError] = useState("");
  const [mileageError, setMileageError] = useState("");
  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");
  const [yearError, setYearError] = useState("");
  const [engineError, setEngineError] = useState("");

  const onSubmit = () => {
    history.push("/onboarding/3");
  };

  const yearRange = (years: number[]) => {
    if (years.length === 0) return [];

    const sortedYears = [...years].sort((a, b) => a - b);
    const firstYear = sortedYears[0];
    const lastYear = sortedYears[sortedYears.length - 1];
    const fullRange = [];

    for (let year = firstYear; year <= lastYear; year++) {
      fullRange.push(year);
    }

    return fullRange;
  };

  const getVariantDisplayName = (
    variant: VariantList,
    allVariants: VariantList[],
  ): string => {
    let displayName = variant.variant;

    const sameVariants = allVariants.filter(
      (v) => v.variant === variant.variant,
    );

    if (sameVariants.length > 1) {
      const nmdiff = sameVariants.some((v) => v.nm !== variant.nm);

      if (nmdiff) {
        displayName += ` (${variant.nm} NM)`;
      }

      const cc2diff = sameVariants.some((v) => v.cc2 !== variant.cc2);

      if (cc2diff) {
        let modelCalss = variant.model_class;
        let modelClassParts = modelCalss.split("-");
        let getModelClass = modelClassParts[modelClassParts.length - 1].trim();
        displayName += `- (${getModelClass})`;
      }
    }

    return displayName;
  };

  const StyledTooltip = styled(
    ({ className, ...props }: TooltipProps & { className?: string }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ),
  )(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: "#344054",
      fontSize: "14px",
      borderRadius: "8px",
      padding: "10px 15px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#FFFFFF",
    },
  }));

  // Autopopulate state variables
  const [autoCurrentMake, setAutoCurrentMake] = useState<string>("");
  const [autoSelectedModel, setAutoSelectedModel] = useState<string>("");
  const [autoDisplayDisplacement, setAutoDisplayDisplacement] = useState<
    number | null
  >(null);
  const [autoFuelType, setAutoFuelType] = useState<string>("");
  const [autoSelectedVariant, setAutoSelectedVariant] = useState<string | null>(
    null,
  );
  const [autoVehicleYear, setAutoVehicleYear] = useState<number | string>("");
  const [autoGearboxType, setAutoGearboxType] = useState<string>("");
  const [autoGearboxDriveType, setAutoGearboxDriveType] = useState<string>("");
  const [vinErrorPopup, setVinErrorPopup] = useState({
    open: false,
    message: "",
  });

  const populateAutoCarDetails = (carDetailsData: any): void => {
    setAutoCurrentMake(carDetailsData.make || "");
    setAutoSelectedModel(carDetailsData.desc || "");
    setAutoDisplayDisplacement(
      carDetailsData.displayDisplacement !== undefined
        ? carDetailsData.displayDisplacement
        : carDetailsData.displacement
          ? parseFloat(carDetailsData.displacement) / 1000
          : null,
    );
    setAutoSelectedVariant(carDetailsData.variant || null);
    if (carDetailsData.fuel_type) setAutoFuelType(carDetailsData.fuel_type);
    if (carDetailsData.intro_date) {
      const year = new Date(carDetailsData.intro_date).getFullYear();
      setAutoVehicleYear(year);
    }
    if (carDetailsData.transmission)
      setAutoGearboxType(carDetailsData.transmission || "");
    if (carDetailsData.drive) setAutoGearboxDriveType(carDetailsData.drive);
  };

  const resetManualInputfields = () => {
    // Get the current saved data
    const savedData = JSON.parse(localStorage.getItem("modelMakeData") || "{}");
    const { vehiclereg, vehicleMilleage } = savedData;
    const newData = {
      vehiclereg,
      vehicleMilleage,
      selectedRegistrationNumber: "",
      selectedModel: "",
      selectedModelYear: 0,
      selectedModelAge: "",
      selectedEngine: {},
      selectedGearboxType: "",
      selectedGearboxDrive: "",
      selectedVariant: null,
      CurrentMake: "",
    };

    localStorage.setItem("modelMakeData", JSON.stringify(newData));
    setSelectedRegistrationNumber("");
    setSelectedModel("");
    setSelectedModelYear(0);
    setSelectedModelAge("");
    setSelectedEngine({});
    setSelectedGearboxType("");
    setSelectedGearboxDrive("");
    setSelectedVariant(null);
    setCurrentMake("");
  };

  const updateVehicleDetails = (carDetailsData: any) => {
    const year = new Date(carDetailsData.intro_date).getFullYear();
    const currentYear = new Date().getFullYear();
    const age = currentYear - year;

    const updatedCarDetails = {
      ...carDetailsData,
      year,
      vehicle_make: carDetailsData.make,

      displayDisplacement:
        carDetailsData.displayDisplacement !== undefined
          ? carDetailsData.displayDisplacement
          : carDetailsData.displacement
            ? parseFloat(carDetailsData.displacement) / 1000
            : null,
      vehicleMilleage,
      registration_number: vehiclereg,
      age,
    };

    localStorage.setItem("vehicle-details", JSON.stringify(updatedCarDetails));
    console.log("All the Data I need for estimate: ", updatedCarDetails);
  };

  //reset the populated fields
  const resetAutoCarDetails = (): void => {
    setAutoCurrentMake("");
    setAutoSelectedModel("");
    setAutoDisplayDisplacement(null);
    setAutoFuelType("");
    setAutoSelectedVariant(null);
    setAutoVehicleYear("");
    setAutoGearboxType("");
    setAutoGearboxDriveType("");
  };

  const isTokenExpired = (umcCodesResponse: any): boolean => {
    // Check if the response message contains "Unauthorised"
    if (
      !umcCodesResponse?.umc_codes &&
      umcCodesResponse?.response?.message &&
      umcCodesResponse.response.message.includes("Unauthorised")
    ) {
      return true;
    }
    return false;
  };

  const showError = (message: string) => {
    console.error(message);
    setVinErrorPopup({ open: true, message });
    setIsLoading(false);
  };

  const handleVinLookup = async () => {
    // pull the auto autocomplete data.
    try {
      setIsLoading(true);
      const vinRegistrationData = await getTheVinRegistrationNumber(vehiclereg);

      if (!vinRegistrationData) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }

      const extractedPayload = vinRegistrationData.extract_payload;

      if (!extractedPayload) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }
      const vinExtract = extractedPayload?.VechileIdentificationNumber;

      if (!vinExtract) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }
      const carMake = extractedPayload?.CarMake?.CurrentTextValue || "";

      const payloadObject = {
        access_token: emsAuthToken,
        vin: vinExtract,
      };

      const payloadString = JSON.stringify(payloadObject);

      const getUmcCodes = await umcCodes(payloadString);
      console.log("The UMC: ", getUmcCodes);
      if (isTokenExpired(getUmcCodes)) {
        console.error("Token expired, redirecting to onboarding.");
        setIsLoading(false);
        history.push("/onboarding/1");
        return;
      }

      const umcCodeArray = getUmcCodes?.umc_codes?.umc_codes || [];

      if (umcCodeArray.length === 0) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }

      const decodeUmcCode = umcCodeArray.length > 0 ? umcCodeArray[0] : null;

      if (!decodeUmcCode) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }

      const vinLookUp = {
        carMake: carMake,
        umc_code: decodeUmcCode,
      };

      localStorage.setItem("vinLookUp", JSON.stringify(vinLookUp));
      const umcStringPayload = JSON.stringify(vinLookUp);

      const getUmcDetails = await carDetails(umcStringPayload);

      if (!getUmcDetails) {
        showError(
          "We couldn't find your vehicle. Please check the number plate or add details manually.",
        );
        setIsLoading(false);
        return;
      }

      localStorage.setItem("umcCarDetails", JSON.stringify(getUmcDetails));

      // Auto populate the input fields with the return data
      const carDetailsData = getUmcDetails?.matching_models?.[0];

      //We will extract the vin hear
      if (carDetailsData) {
        carDetailsData.vin = vinExtract;
      }
      console.log("Populating Car details", carDetailsData);
      populateAutoCarDetails(carDetailsData);

      //store required data for estimate
      updateVehicleDetails(carDetailsData);

      setAutoPopulate(true);
      setShowManualFields(false);
      setIsLoading(false);
    } catch (error) {
      console.log("Internal server error: ", error);
      let errorMessage = "";
      console.log(error.status);
      if (error && error.msg && error.msg.includes("500")) {
        errorMessage =
          "We couldn't find your vehicle. Please check the number plate or add details manually.";
      } else if (error && error.msg) {
        errorMessage = error.msg;
      } else {
        errorMessage =
          "We couldn't find your vehicle. Please check the number plate or add details manually.";
      }
      setVinErrorPopup({ open: true, message: errorMessage });
      setIsLoading(false);
    }
  };

  const handleShowFields = () => {
    if (autoPopulate) {
      resetAutoCarDetails();
      resetManualInputfields();
      setAutoPopulate(false);
    }
    setShowManualFields((prev) => !prev);
  };

  const isNextDisabled = autoPopulate
    ? !vehiclereg ||
      !vehicleMilleage ||
      !autoCurrentMake ||
      !autoSelectedModel ||
      !autoVehicleYear ||
      autoDisplayDisplacement === null ||
      !autoFuelType ||
      !autoGearboxType ||
      !autoGearboxDriveType ||
      !autoSelectedVariant ||
      !!registrationError
    : !vehiclereg ||
      !vehicleMilleage ||
      !selectedGearboxType ||
      !selectedGearboxDrive ||
      !selectedVariant ||
      !!registrationError;

  return (
    <PageLayout animationData={dataAnimation}>
      <HeaderText headerText="VEHICLE DETAILS" headerImage={servisorIcon} />
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",
          paddingBottom: "100px",
          position: "relative",
          paddingTop: { xs: "50px", lg: "82px" },
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
          }}
        >
          <img src={vehicleTag} alt="/" />
        </Box>

        <SubHeaderText headerText="Tell us a little more about the vehicle you’d like to have serviced" />

        <Container
          sx={{
            height: { xs: "100%", lg: "100%" },
            width: { xs: "90%", lg: "70%" },
            marginTop: { xs: "-10px", lg: "0" },

            borderRadius: { xs: "4px", lg: "4px" },
            backgroundColor: "#FFF",
            paddingX: { xs: "20px", lg: "0" },
          }}
          disableGutters
        >
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              alignSelf: "left",
              width: { xs: "100%", lg: "70%" },
              paddingTop: { xs: "20px", lg: "0" },
            }}
          >
            <img src={vehicleTag} alt="/" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              width: "100%",
              paddingTop: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                width: { xs: "100%", lg: "100%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--gray-700, #344054)",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Current Mileage{" "}
                </Typography>

                <TextField
                  onFocus={() => setVehicleMilleageFocus(true)}
                  onBlur={() => setVehicleMilleageFocus(false)}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      width: "50%",
                      border:
                        vehicleMilleageEdited &&
                        !vehicleMilleageFocus &&
                        (mileageError || !vehicleMilleage)
                          ? "1px solid red"
                          : "",

                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      '& input[type="number"]': {
                        "-moz-appearance": "textfield",
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledTooltip
                          title="Exact km’s isn’t necessary—just a close estimate for accuracy."
                          placement="right-end"
                          arrow
                        >
                          <img
                            src={kmIcon}
                            alt="km-icon"
                            style={{ cursor: "pointer" }}
                          />
                        </StyledTooltip>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="80 000"
                  type="number"
                  name="vehicleMilleage"
                  value={vehicleMilleage}
                  onChange={(ev) => {
                    if (!vehicleMilleageEdited) {
                      setVehicleMilleageEdited(true);
                    }

                    const newMileage = ev.target.value;

                    setVehicleMilleage(newMileage);
                    setMileageError("");
                  }}
                  error={Boolean(mileageError)}
                  helperText={mileageError}
                />
              </Box>

              <Typography
                sx={{
                  color: "var(--gray-700, #344054)",
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingTop: "15px",
                }}
              >
                Vehicle Registration{" "}
              </Typography>

              <TextField
                onFocus={() => setVehicleregFocus(true)}
                onBlur={() => setVehicleregFocus(false)}
                InputProps={{
                  sx: {
                    "& input": {
                      color: "#000",
                      caretColor: "#000",
                      "&::placeholder": { color: "#000" },
                    },
                    "& .MuiInputBase-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset",
                      WebkitTextFillColor: "#000 !important",
                      caretColor: "#000 !important",
                    },
                    "& .MuiInputBase-input:-webkit-autofill:focus": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset",
                      WebkitTextFillColor: "#000 !important",
                      caretColor: "#000 !important",
                    },
                    "& .MuiInputBase-input:-webkit-autofill:hover": {
                      WebkitBoxShadow: "0 0 0 1000px #fff inset",
                      WebkitTextFillColor: "#000 !important",
                      caretColor: "#000 !important",
                    },
                    borderRadius: "8px",
                    paddingRight: "8px",
                    border:
                      vehicleregEdited &&
                      !vehicleregFocus &&
                      (registrationError || vehiclereg.trim() === "")
                        ? "1px solid red"
                        : "",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box>
                        <Button
                          onClick={handleVinLookup}
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#dfe9ff",
                            borderRadius: "5px",
                            margin: "3px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "700",
                              color: "#1959aa",
                            }}
                          >
                            Find my car
                          </Typography>
                        </Button>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter Registration Number"
                fullWidth
                variant="outlined"
                type="text"
                name="vehiclereg"
                value={vehiclereg}
                onChange={(ev) => {
                  const reg = ev.target.value;

                  if (!vehicleregEdited) {
                    setVehicleregEdited(true);
                  }
                  if (reg.length !== 8) {
                    setRegistrationError(
                      "Vehicle Registration must be exactly 8 characters.",
                    );
                  } else if (!/^[A-Za-z0-9- ]*$/.test(reg)) {
                    setRegistrationError("Vehicle Registration invalid.");
                  } else if (reg.startsWith(" ")) {
                    setRegistrationError(
                      "Vehicle Registration cannot start with a space.",
                    );
                  } else {
                    setRegistrationError("");
                  }
                  setVehiclereg(reg);
                  setSelectedRegistrationNumber(reg);
                }}
                error={Boolean(registrationError)}
                helperText={registrationError}
              />
            </Box>
          </Box>

          <Box sx={{ paddingTop: "5px" }}>
            {" "}
            <Typography
              sx={{
                color: "#212121",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Enter your Registration number to quickly identify your car.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "8px" }}>
            <Button
              onClick={handleShowFields}
              sx={{
                padding: "1px",
                textTransform: "none",
                margin: "3px",
                marginLeft: "-1px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#002064",
                }}
              >
                Or find your car by make, model and year.
              </Typography>
            </Button>
          </Box>
          {autoPopulate && (
            <AutoCompleteFields
              autoCurrentMake={autoCurrentMake}
              autoSelectedModel={autoSelectedModel}
              autoVehicleYear={autoVehicleYear}
              autoDisplayDisplacement={autoDisplayDisplacement}
              autoFuelType={autoFuelType}
              autoGearboxType={autoGearboxType}
              autoGearboxDriveType={autoGearboxDriveType}
              autoSelectedVariant={autoSelectedVariant}
            />
          )}
          {showManualFields && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%",
                paddingTop: "25px",
              }}
            >
              <Box>
                {loadingMake ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth error={Boolean(makeError)}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Make{" "}
                      </Typography>

                      <Select
                        displayEmpty
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                        }}
                        onChange={(ev: any) => {
                          Makes?.map((currentVehicle: any, index: number) => {
                            if (
                              currentVehicle.make_description ===
                              ev.target.value
                            ) {
                              const carMake =
                                currentVehicle.make_description.toUpperCase();
                              const bodyData = JSON.stringify({
                                access_token: emsAuthToken,
                                model: carMake,
                              });

                              //reset the vehicle details
                              localStorage.removeItem("vehicle-details");

                              getManualModelApi(
                                setLoadingModel,
                                setVariants,
                                setClicked,
                                emsGetVehicleModels,
                                bodyData,
                              );
                              setCurrentMake(carMake);

                              //reset the dependent fields
                              setSelectedModel("");
                              setSelectedModelYear(0);
                              setSelectedEngine({});
                              setEngineList([]);
                              setSelectedGearboxType("");
                              setSelectedGearboxDrive("");
                              setGearboxTypeList([]);
                              setSelectedVariant(null);

                              setMakeError("");
                            }
                          });
                        }}
                        value={CurrentMake || ""}
                        renderValue={(selectedMake) => {
                          if (selectedMake === "") {
                            return <span>Select Make</span>;
                          } else {
                            return selectedMake;
                          }
                        }}
                      >
                        <MenuItem selected disabled>
                          Select Make
                        </MenuItem>
                        {Makes?.sort((a: any, b: any) =>
                          a.make_description.localeCompare(b.make_description),
                        ).map((currentVehicle: any, index: number) => (
                          <MenuItem
                            key={index}
                            value={currentVehicle.make_description}
                          >
                            {currentVehicle.make_description}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                )}
              </Box>

              <Box>
                {loadingModel ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth error={Boolean(modelError)}>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Model{" "}
                      </Typography>

                      <Select
                        displayEmpty
                        fullWidth
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        // label="Select Model"
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        // disabled={!Variants}
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                          "&& .Mui": {
                            color: "pink",
                          },
                        }}
                        onChange={(ev: any) => {
                          Variants?.map(
                            (currentVehicle: any, index: number) => {
                              if (currentVehicle === ev.target.value) {
                                const bodyData = JSON.stringify({
                                  model: CurrentMake,
                                  desc: currentVehicle,
                                });

                                getModelYearsApi(
                                  setLoadingModel,
                                  setClicked,
                                  setVehicleYears,
                                  emsGetModelYears,
                                  bodyData,
                                );

                                // Reset the dependent fields
                                setSelectedModelYear(0);
                                setSelectedEngine({});
                                setEngineList([]);
                                setSelectedGearboxType("");
                                setSelectedGearboxDrive("");
                                setSelectedVariant(null);

                                setSelectedModel(currentVehicle);

                                setModelError("");
                              }
                            },
                          );
                        }}
                        value={selectedModel || ""}
                        renderValue={(modelSelected) => {
                          if (modelSelected === "") {
                            return <span>Select Model</span>;
                          } else {
                            return modelSelected;
                          }
                        }}
                        placeholder={customModel.header}
                      >
                        <MenuItem selected disabled>
                          Select Model
                        </MenuItem>
                        {Variants?.sort((a: any, b: any) => a - b).map(
                          (currentVehicle: any, index: number) => {
                            return (
                              <MenuItem key={index} value={currentVehicle}>
                                {currentVehicle}
                              </MenuItem>
                            );
                          },
                        )}
                      </Select>
                    </Box>
                  </FormControl>
                )}
              </Box>

              <Box>
                {loadingYears ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth error={Boolean(yearError)}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Year{" "}
                      </Typography>

                      <Select
                        displayEmpty
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        disabled={!Variants}
                        id="demo-simple-select"
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                        }}
                        value={selectedModelYear || ""}
                        onChange={(ev: any) => {
                          const selectedYear = parseInt(ev.target.value);
                          let closestYear = selectedYear;

                          if (!vehicleYears.includes(selectedYear)) {
                            const sortedYears = vehicleYears.sort(
                              (a, b) => a - b,
                            );
                            const previousYears = sortedYears.filter(
                              (year) => year < selectedYear,
                            );
                            closestYear =
                              previousYears[previousYears.length - 1] ||
                              sortedYears[0];
                          }

                          const bodyData = JSON.stringify({
                            model: CurrentMake,
                            desc: selectedModel,
                            year: closestYear,
                          });

                          getModelEngineDataApi(
                            setLoadingEngine,
                            setClicked,
                            setEngineList,
                            emsGetModelEngineData,
                            bodyData,
                            vehicleYears,
                            closestYear,
                          );

                          // Reset the dependent fields
                          setSelectedEngine({});
                          setSelectedGearboxType("");
                          setSelectedGearboxDrive("");
                          setSelectedVariant(null);

                          //This is to also reset the model detail list for the variant selected
                          setModelDetailsList([]);

                          setSelectedModelYear(closestYear);
                          calculateVehicleAge(closestYear, setSelectedModelAge);
                        }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <Typography>Select Year</Typography>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem selected disabled>
                          Select Year
                        </MenuItem>
                        {yearRange(vehicleYears)?.map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                )}
              </Box>

              <Box>
                {loadingEngine ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth error={Boolean(engineError)}>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Engine{" "}
                      </Typography>{" "}
                      <Select
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        disabled={engineList === 0}
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                        }}
                        onChange={(event: any) => {
                          const newEngine = engineList.find(
                            (engine: any) =>
                              engine.displacement === event.target.value,
                          );
                          if (
                            newEngine &&
                            newEngine.displacement !==
                              selectedEngine?.displacement
                          ) {
                            setSelectedEngine(newEngine);
                          }

                          // Reset the dependent fields
                          setSelectedGearboxType("");
                          setSelectedGearboxDrive("");
                          setSelectedVariant(null);
                        }}
                        value={
                          selectedEngine &&
                          selectedEngine.display &&
                          selectedEngine.fuel_type
                            ? `${selectedEngine.display} L - ${selectedEngine.fuel_type}`
                            : ""
                        }
                        renderValue={(value: any) => {
                          if (value === "") {
                            return <span>Select Engine</span>;
                          } else {
                            const currentEngine = engineList.find(
                              (engine: any) => engine.displacement === value,
                            );
                            if (currentEngine) {
                              return `${currentEngine.display} L - ${currentEngine.fuel_type}`;
                            } else {
                              return value;
                            }
                          }
                        }}
                        placeholder={customYear.header}
                      >
                        <MenuItem disabled>Select Engine</MenuItem>
                        {engineList
                          ?.sort((a: any, b: any) =>
                            a.displacement.localeCompare(b.displacement),
                          )
                          .map((currentVehicle: any, index: number) => {
                            return (
                              <MenuItem
                                key={index}
                                value={currentVehicle.displacement}
                              >
                                {`${currentVehicle.display} L - ${currentVehicle.fuel_type}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Box>
                  </FormControl>
                )}

                {loadingGearboxDrive ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Box pt={2}>
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-700, #344054)",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Gearbox Type{" "}
                          </Typography>
                          <Select
                            IconComponent={(props) => (
                              <img src={plusIcon} alt="/" {...props} />
                            )}
                            displayEmpty
                            // disabled={engineList === 0}
                            sx={{
                              textAlign: "start",
                              borderRadius: "8px",
                            }}
                            value={selectedGearboxType || ""}
                            onChange={(ev: any) => {
                              const currentVehicle = ev.target.value;
                              const bodyData = JSON.stringify({
                                model: CurrentMake,
                                desc: selectedModel,
                                year: selectedModelYear,
                                displacement: selectedEngine.displacement,
                                fuel_type: selectedEngine.fuel_type,
                                model_gearbox_types: currentVehicle,
                              });

                              getModelGearboxDriveTypeApi(
                                setLoadingGearboxDrive,
                                setClicked,
                                setGearboxDriveList,
                                emsGetModelGearboxDrive,
                                bodyData,
                                vehicleYears,
                                selectedModelYear,
                              );
                              setSelectedGearboxType(currentVehicle);

                              // Reset the dependent fields
                              setSelectedGearboxDrive("");
                              setSelectedVariant(null);
                            }}
                            renderValue={(gearBoxTypeSelected) => {
                              if (gearBoxTypeSelected === "") {
                                return <span>Select Gear Type</span>;
                              } else {
                                return gearBoxTypeSelected;
                              }
                            }}
                            placeholder={customGear.header}
                          >
                            <MenuItem disabled>Select Gear Type</MenuItem>
                            {gearboxTypeList
                              ?.sort((a: any, b: any) => a.localeCompare(b))
                              .map((currentVehicle: any, index: any) => (
                                <MenuItem key={index} value={currentVehicle}>
                                  {currentVehicle}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Box>
                    {loadingGearboxType ? (
                      <CircularProgress />
                    ) : (
                      <Box pt={2}>
                        <FormControl fullWidth>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "var(--gray-700, #344054)",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Gearbox Drive type{" "}
                            </Typography>{" "}
                            <Select
                              IconComponent={(props) => (
                                <img src={plusIcon} alt="/" {...props} />
                              )}
                              displayEmpty
                              // disabled={gearboxTypeList.length === 0}
                              sx={{
                                textAlign: "start",
                                borderRadius: "8px",
                              }}
                              onChange={(ev: any) => {
                                const currentVehicle = ev.target.value;

                                const bodyData = JSON.stringify({
                                  model: CurrentMake,
                                  desc: selectedModel,
                                  year: selectedModelYear,
                                  displacement: selectedEngine.displacement,
                                  fuel_type: selectedEngine.fuel_type,
                                  model_gearbox_types: selectedGearboxType,
                                  model_drive_types: currentVehicle,
                                });

                                getModelDetailsApi(
                                  setLoadingModelDetails,
                                  setClicked,
                                  setModelDetailsList,
                                  emsGetModelDetails,
                                  bodyData,
                                  vehicleYears,
                                  selectedModelYear,
                                );
                                setSelectedGearboxDrive(currentVehicle);

                                // Reset the dependent fields
                                setSelectedVariant(null);
                              }}
                              value={selectedGearboxDrive || ""}
                              renderValue={(gearBoxTypeSelected) => {
                                if (gearBoxTypeSelected === "") {
                                  return <span>Select Gearbox Drive Type</span>;
                                } else {
                                  return gearBoxTypeSelected;
                                }
                              }}
                              placeholder={customGear.header}
                            >
                              <MenuItem disabled>
                                Select Gearbox Drive Type
                              </MenuItem>
                              {gearboxDriveList
                                ?.sort((a: any, b: any) => a.localeCompare(b))
                                .map((currentVehicle: any, index: number) => (
                                  <MenuItem key={index} value={currentVehicle}>
                                    {currentVehicle}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Box>
                        </FormControl>
                      </Box>
                    )}
                    {loadingModelDetails ? (
                      <CircularProgress />
                    ) : (
                      <Box pt={2}>
                        <FormControl fullWidth>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "var(--gray-700, #344054)",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Variant{" "}
                            </Typography>{" "}
                            <Select
                              IconComponent={(props) => (
                                <img src={plusIcon} alt="/" {...props} />
                              )}
                              displayEmpty
                              value={selectedVariant?.umc || ""}
                              onChange={(event) => {
                                const selectedVariant = modelDetailsList.find(
                                  (variant: any) =>
                                    variant.umc === event.target.value,
                                );
                                setSelectedVariant(selectedVariant || {});
                                localStorage.setItem(
                                  "vehicle-details",
                                  JSON.stringify({
                                    ...selectedVariant,
                                    vehicle_make: CurrentMake,
                                    displayDisplacement: selectedEngine.display,
                                    vehicleMilleage: vehicleMilleage,
                                    registration_number: vehiclereg,
                                    age: selectedModelAge,
                                  }),
                                );
                              }}
                              renderValue={(selectedUmc) => {
                                if (selectedUmc === "") {
                                  return <span>Select Variant</span>;
                                } else {
                                  const umcVariant = modelDetailsList.find(
                                    (v: VariantList) => v.umc === selectedUmc,
                                  );
                                  if (umcVariant) {
                                    return getVariantDisplayName(
                                      umcVariant,
                                      modelDetailsList,
                                    );
                                  } else if (selectedVariant) {
                                    return getVariantDisplayName(
                                      selectedVariant,
                                      [selectedVariant],
                                    );
                                  } else {
                                    return <span>Select Variant</span>;
                                  }
                                }
                              }}
                              sx={{
                                textAlign: "start",
                                borderRadius: "8px",
                              }}
                            >
                              <MenuItem disabled>Select Variant</MenuItem>
                              {modelDetailsList
                                ?.sort((a: any, b: any) => a - b)
                                .map((currentVehicle: any, index: number) => (
                                  <MenuItem
                                    key={index}
                                    value={currentVehicle.umc}
                                  >
                                    {/* {currentVehicle.variant} */}
                                    {getVariantDisplayName(
                                      currentVehicle,
                                      modelDetailsList,
                                    )}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Box>
                        </FormControl>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <IssueHelpButton />
            </Box>
          )}

          <Paper
            sx={{
              position: { xs: "fixed", lg: "fixed" },
              bottom: 0,
              right: 0,
              zIndex: "99",
              paddingY: "20px",
              display: "flex",
              borderRadius: " 0",
              alignItems: "center",
              justifyContent: "center",

              width: { xs: "100%", lg: "50%" },
            }}
            elevation={0}
          >
            <BottomNavigation
              sx={{
                width: { xs: "100%", lg: "70%" },
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box
                py={3}
                sx={{
                  display: { xs: "flex" },
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Button
                    onClick={() => history.push("/onboarding/1")}
                    sx={{
                      padding: "0",
                      margin: "0",
                      display: "flex",
                      borderRadius: "0",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={NextIcon}
                        alt="/"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "rotate(180deg)",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#101828",
                      }}
                    >
                      Back
                    </Typography>
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ProgressBar value={50} />
                </Box>
                <Box>
                  <Button
                    disabled={isNextDisabled}
                    onClick={() => onSubmit()}
                    sx={{
                      padding: "0",
                      margin: "0",
                      display: "flex",
                      borderRadius: "0",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: isNextDisabled ? "grey" : "#101828",
                      }}
                    >
                      Next
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={NextIcon}
                        alt="/"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </Box>{" "}
                  </Button>
                </Box>
              </Box>
            </BottomNavigation>
          </Paper>
        </Container>
      </Box>
      {isLoading && <Loader />}
      <VinPopupComponent
        open={vinErrorPopup.open}
        message={vinErrorPopup.message}
        onClose={() => setVinErrorPopup({ open: false, message: "" })}
      />
    </PageLayout>
  );
};

export default ManualSearch;
