import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

type ErrorPopupProps = {
  open: boolean;
  message: string;
  onClose: () => void;
};

const VinPopupComponent: React.FC<ErrorPopupProps> = ({
  open,
  message,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <InfoIcon sx={{ color: "#1959aa" }} />

        <Typography
          sx={{ fontSize: "20px", fontWeight: "700", color: "#1959aa" }}
        >
          Not Found
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VinPopupComponent;
