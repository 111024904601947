import React, { useEffect } from "react";
import servisorIcon from "../../../assets/servisorWhiteLogo.png";
import plusIcon from "../../../assets/forms/plus.svg";
import vehicleTag from "../../../assets/tags/locationnew.svg";
import dataAnimation from "../../../assets/lottie/Servisor_04_Service Location.json";
import NextIcon from "../../../assets/icons/ChevronRightFilled.svg";

import {
  Button,
  Box,
  Container,
  Typography,
  Select,
  FormControl,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  BottomNavigation,
  Paper,
  CircularProgress,
} from "@mui/material";

import { useState } from "react";
import "../Onboarding.scss";
import { useHistory } from "react-router-dom";

import { Geocode, initService } from "../../../services/apis/location.service";
import { getAllActiveWorkshops } from "../../../services/apis/worksshopsData.service";

import { LocationModel } from "../../../models/LocationModel";
import { useGlobalContext } from "../../../services/context/globalContext";
import { HeaderText } from "../../../components/common/HeaderText";
import { SubHeaderText } from "../../../components/common/SubHeaderText";
import ProgressBar from "../../../components/common/ProgressBar";
import { PageLayout } from "../../../components/layout/PageLayout";
import IssueHelpButton from "../../../components/common/IssueHelpButton";

interface Province {
  id: number;
  name: string;
}

interface Area {
  area: string;
  code: string;
  rate: number;
  provinceName?: string;
}

export const LocationEvaluation: React.FC<any> = ({
  clicked,
  setClicked,
  step,
  backStep,
  forwardStep,
  progressValue,
}) => {
  // @ts-ignore
  const { setAreaCodeState } = useGlobalContext();

  const history = useHistory();
  const [area, setArea]: any = useState([]);
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);
  const [locationsList, setLocationsList] = useState([]);
  const [location, setLocation] = useState(new LocationModel({}));
  const [searchTerm, setSearchTerm]: any = useState(null);
  const [showSearch, setSearch] = useState(false);
  const [areaCode, setAreaCode]: any = useState("");
  const [selectedProvince, setSelectedProvince]: any = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  const [filteredAreas, setFilteredAreas] = useState<Area[]>([]);
  const [workShopData, setWorkShopData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [filterProvinces, setFilterProvinces] = useState<Province[]>([]);
  const [isProvinceLoading, setIsProvinceLoading] = useState(true);

  const customLocation = {
    header: "Select Location",
    subHeader: "Select a location.",
  };

  const allWorkShops = async () => {
    setIsLoading(true);
    try {
      const allworkshopData = await getAllActiveWorkshops();
      setWorkShopData(allworkshopData.data);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching workshop data: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    allWorkShops();
  }, []);
  /**
   * Saves the selected location, formats the area code, and navigates to the confirmation page.
   */

  const saveLocation = () => {
    // !B - Relevant area code stored in local storage
    const formatedAreaCode = areaCode;
    setAreaCodeState(JSON.stringify(parseFloat(formatedAreaCode)));
    localStorage.setItem("areaCode", formatedAreaCode);
    // !Temporarily hard coding the rate and area for use in the calculations
    localStorage.setItem("temporary-rate-data", JSON.stringify(selectedArea));

    localStorage.setItem("location", JSON.stringify(location));

    history.push("/confirmation");
  };

  /**
   * Assigns a location based on the provided event description, retrieves its details using Geocode,
   * and updates the location state.
   *
   * @param {Object} event - The event object containing the location description.
   */
  const assignLocation = (event: any) => {
    Geocode(event.description).then((response: any) => {
      location.location = [
        response.geometry.location.lat(),
        response.geometry.location.lng(),
      ];
      location.description = response.formatted_address;
      location.place_id = response.place_id;
      setSearchTerm(location.description);
      setLocation(new LocationModel(location));
      setSearch(false);
      setLocationSelected(true);
    });
  };

  const provinces = [
    { id: 1, name: "Eastern Cape" },
    { id: 2, name: "Free State" },
    { id: 3, name: "Gauteng" },
    { id: 4, name: "KwaZulu-Natal" },
    { id: 5, name: "Limpopo" },
    { id: 6, name: "Mpumalanga" },
    { id: 7, name: "North West" },
    { id: 8, name: "Northern Cape" },
    { id: 9, name: "Western Cape" },
  ];

  /**
   * Performs an effect to retrieve service areas and set them in the component state.
   * This effect runs once after the component is mounted.
   */
  useEffect(() => {
    // ! Will implement area call once it is availble for now we hard code values.
    // getArea().then((data) => {
    //     const serviceAreas = Object.entries(data[0].province[0]).map(
    //         ([province, areaCode]) => {
    //             const myObj = {
    //                 area: province,
    //                 code: areaCode,
    //             };
    //             return myObj;
    //         }
    //     );
    //     setArea(serviceAreas);
    // });

    // Exclusive of VAT
    // setArea([
    //   { area: "Fourways", code: "0001", rate: 900 },
    //   { area: "Bethal", code: "0002", rate: 867.5 },
    //   { area: "Emalahleni", code: "0003", rate: 800 },
    //   { area: "Middelburg", code: "0004", rate: 800 },
    //   { area: "Potchesfstroom", code: "0005", rate: 800 },
    //   { area: "Randburg", code: "0006", rate: 900 },
    //   { area: "Paulshof", code: "0007", rate: 900 },
    //   { area: "Secunda", code: "0008", rate: 867.5 },
    //   { area: "Bryanston", code: "0009", rate: 900 },
    //   { area: "Honeydew", code: "0010", rate: 900 },
    //   { area: "Sunninghill", code: "0011", rate: 900 },
    // ]);

    const initalAreas: Area[] = [
      { area: "Fourways", code: "0001", rate: 900 },
      { area: "Bethal", code: "0002", rate: 867.5 },
      { area: "Emalahleni", code: "0003", rate: 800 },
      { area: "Middelburg", code: "0004", rate: 800 },
      { area: "Potchesfstroom", code: "0005", rate: 800 },
      { area: "Randburg", code: "0006", rate: 900 },
      { area: "Paulshof", code: "0007", rate: 900 },
      { area: "Secunda", code: "0008", rate: 867.5 },
      { area: "Bryanston", code: "0009", rate: 900 },
      { area: "Honeydew", code: "0010", rate: 900 },
      { area: "Sunninghill", code: "0011", rate: 900 },
      { area: "Roodepoort", code: "0012", rate: 900 },
      { area: "Edenvale", code: "0013", rate: 900 },
    ];

    const fetchProvinceAreas = async (areas: Area[]) => {
      try {
        const updateAreas = await Promise.all(
          areas.map(async (areaItem) => {
            try {
              const response: any = await Geocode(
                areaItem.area + ", South Africa",
              );
              const addressComponents = response.address_components;
              const provinceComponent = addressComponents.find((part: any) =>
                part.types.includes("administrative_area_level_1"),
              );
              const provinceName = provinceComponent
                ? provinceComponent.long_name
                : null;

              return {
                ...areaItem,
                provinceName,
              };
            } catch (error) {
              console.log(
                "Error Fetching province for area: ",
                areaItem.area,
                error,
              );
            }
          }),
        );
        const validAreas = updateAreas.filter(Boolean) as Area[];

        validAreas.sort((a, b) => a.area.localeCompare(b.area));
        // console.log("The valid areas: ", validAreas)
        setArea(validAreas);
      } catch (error) {
        console.log("Error Fetching province for area: ", error);
        return null;
      } finally {
        setIsProvinceLoading(false);
      }
    };

    fetchProvinceAreas(initalAreas);
  }, []);

  //Filter Provinces
  const filterAllProvinces = () => {
    if (area.length > 0 && workShopData.length > 0) {
      const filtered = provinces.filter((province) => {
        const hasAreaCode = area.some(
          (areaItem: Area) =>
            areaItem && areaItem.provinceName === province.name,
        );
        const hasworkshop = workShopData.some(
          (workshop: any) => workshop.province?.name === province.name,
        );
        return hasAreaCode && hasworkshop;
      });
      setFilterProvinces(filtered);
    }
  };

  useEffect(() => {
    filterAllProvinces();
  }, [area, workShopData]);

  // Inclusive of VAT
  //vat = 0.15
  //rateInc = rate + (rate * vat)
  // setArea([
  //     { area: 'Fourways', code: '0001', rate: 920 },
  //     { area: 'Bethal', code: '0002', rate: 943 },
  //     { area: 'Emalahleni', code: '0003', rate: 917.70 },
  //     { area: 'Middelburg', code: '0004', rate: 917.70 },
  //     { area: 'Potchesfstroom', code: '0005', rate: 948.75 },
  // ]);
  // }, []);

  return (
    <PageLayout animationData={dataAnimation}>
      <HeaderText headerText="SERVICE LOCATION" headerImage={servisorIcon} />
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",
          paddingBottom: "100px",
          position: "relative",
          paddingTop: { xs: "50px", lg: "100px" },
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
          }}
        >
          <img src={vehicleTag} alt="/" />
        </Box>

        <SubHeaderText headerText="Which area would you like the service to take place?" />

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75%",
              mt: 16,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Container
            disableGutters
            sx={{
              width: { xs: "90%", lg: "70%" },

              marginTop: { xs: "-10px", lg: "0" },
              borderRadius: { xs: "4px", lg: "4px" },
              backgroundColor: "#FFF",
              paddingX: { xs: "20px", lg: "0" },
            }}
          >
            <Box>
              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  alignSelf: "left",
                  width: { xs: "100%", lg: "70%" },
                  paddingTop: { xs: "20px", lg: "0" },
                }}
              >
                <img src={vehicleTag} alt="/" />
              </Box>
              <form>
                <Box sx={{ py: "20px" }}>
                  <FormControl fullWidth>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Province{" "}
                      </Typography>

                      <Select
                        displayEmpty
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                          pr: "14px",
                        }}
                        value={selectedProvince?.id || ""}
                        onChange={(event: any) => {
                          const selectProvinceId = event.target.value as number;
                          const province = filterProvinces.find(
                            (prov) => prov.id === selectProvinceId,
                          );
                          setSelectedProvince(province || null);

                          if (province) {
                            const filtered = area.filter(
                              (areaCode: any) =>
                                areaCode.provinceName === province.name,
                            );
                            setFilteredAreas(filtered);
                          } else {
                            setFilteredAreas([]);
                          }
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Province
                        </MenuItem>

                        {isProvinceLoading ? (
                          <MenuItem disabled>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <CircularProgress size={20} />
                            </Box>
                          </MenuItem>
                        ) : filterProvinces.length > 0 ? (
                          filterProvinces.map((prov) => (
                            <MenuItem key={prov.id} value={prov.id}>
                              {prov.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Provinces available</MenuItem>
                        )}
                      </Select>
                    </Box>
                  </FormControl>

                  <FormControl fullWidth>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        marginTop: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--gray-700, #344054)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Location{" "}
                      </Typography>

                      <Select
                        displayEmpty
                        IconComponent={(props) => (
                          <img src={plusIcon} alt="/" {...props} />
                        )}
                        sx={{
                          textAlign: "start",
                          borderRadius: "8px",
                          pr: "14px",
                        }}
                        // onChange={(event: any) => {
                        //   const formatedAreaCode = event.target.value.code;

                        //   setSelectedArea(event.target.value);
                        //   setAreaCode(event.target.value.code);
                        //   let formattedArea: string = "";

                        //   switch (formatedAreaCode) {
                        //     case "0001":
                        //       formattedArea = "Johannesburg, South Africa";
                        //       break;
                        //     case "0002":
                        //       formattedArea = "Bethal, South Africa";
                        //       break;
                        //     case "0003":
                        //       formattedArea = "Emalahleni, South Africa";
                        //       break;
                        //     case "0004":
                        //       formattedArea = "Middelburg, South Africa";
                        //       break;
                        //     case "0005":
                        //       formattedArea = "Potchesfstroom, South Africa";
                        //       break;
                        //     case "0006":
                        //       formattedArea = "Randburg, South Africa";
                        //       break;
                        //     case "0007":
                        //       formattedArea = "Paulshof, South Africa";
                        //       break;
                        //     case "0008":
                        //       formattedArea = "Secunda, South Africa";
                        //       break;
                        //     case "0009":
                        //       formattedArea = "Bryanston, South Africa";
                        //       break;
                        //     case "0010":
                        //       formattedArea = "Honeydew, South Africa";
                        //       break;
                        //     case "0011":
                        //       formattedArea = "Sunninghill, South Africa";
                        //       break;
                        //   }

                        //   if (event) {
                        //     // @ts-ignore
                        //     setSearchTerm(
                        //       (event.target as HTMLInputElement).value
                        //     );
                        //     // @ts-ignore
                        //     initService(formattedArea).then((response: any) => {
                        //       if (response) {
                        //         if (response.length > 0) {
                        //           assignLocation(response[0]);
                        //         }
                        //       }
                        //     });
                        //   }
                        // }}

                        onChange={(event) => {
                          const selectedCode = event.target.value as string;
                          const locationItem = filteredAreas.find(
                            (item) => item.code === selectedCode,
                          );
                          setSelectedArea(locationItem || null);
                          setAreaCode(locationItem?.code || "");

                          if (locationItem) {
                            setSearchTerm(locationItem.area);
                            initService(locationItem.area).then(
                              (response: any) => {
                                if (response && response.length > 0) {
                                  assignLocation(response[0]);
                                }
                              },
                            );
                          }
                        }}
                        placeholder={customLocation.header}
                      >
                        <MenuItem selected disabled>
                          Select Location
                        </MenuItem>

                        {filteredAreas.length > 0 ? (
                          filteredAreas.map(
                            (locationItem: any, index: number) => (
                              <MenuItem key={index} value={locationItem.code}>
                                {locationItem.area}
                              </MenuItem>
                            ),
                          )
                        ) : (
                          <MenuItem disabled>No locations available</MenuItem>
                        )}
                      </Select>
                    </Box>
                  </FormControl>
                </Box>

                {showSearch ? (
                  <div className="results-box">
                    <List color="light">
                      <Typography variant="h6" className="font-bold">
                        Suggestions
                      </Typography>
                      <hr color="light" />
                      {locationsList.map((locationItem: any, index: number) => {
                        return (
                          <ListItem
                            key={index}
                            onClick={() => assignLocation(locationItem)}
                          >
                            <ListItemText primary={locationItem.description} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                ) : null}
                <IssueHelpButton />

                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    paddingY: "10px",
                    zIndex: "999",
                    width: { xs: "100%", lg: "50%" },
                    borderRadius: "0",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  elevation={0}
                >
                  <BottomNavigation
                    sx={{
                      width: { xs: "100%", lg: "70%" },

                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      py={3}
                      sx={{
                        display: { xs: "flex" },
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Button
                          onClick={() => history.push("/onboarding/3")}
                          sx={{
                            padding: "0",
                            margin: "0",
                            display: "flex",
                            borderRadius: "0",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={NextIcon}
                              alt="/"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                transform: "rotate(180deg)",
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "700",
                              color: "#101828",
                            }}
                          >
                            Back
                          </Typography>
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ProgressBar value={100} />
                      </Box>
                      <Box>
                        <Button
                          disabled={!locationSelected}
                          onClick={() => saveLocation()}
                          sx={{
                            padding: "0",
                            margin: "0",
                            display: "flex",
                            borderRadius: "0",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "700",
                              color: !locationSelected ? "grey" : "#101828",
                            }}
                          >
                            Next
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={NextIcon}
                              alt="/"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            />
                          </Box>
                        </Button>
                      </Box>
                    </Box>
                  </BottomNavigation>
                </Paper>
              </form>
            </Box>
          </Container>
        )}
      </Box>
    </PageLayout>
  );
};
